body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@keyframes shake {

  5%, 45% {
    transform: translate3d(-1px, 0, 0);
  }

  10%, 40% {
    transform: translate3d(2px, 0, 0);
  }

  15%, 25%, 35% {
    transform: translate3d(-4px, 0, 0);
  }

  20%, 30% {
    transform: translate3d(4px, 0, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}
